import React from "react";
import { Page, Seo } from "gatsby-theme-portfolio-minimal";

import MainEquipmentSection, { SubEquipmentSection } from '../components/Equipment';

import '../index.css';

export default function EquipmentPage() {
  return (
    <>
      <Seo title="有限会社 FSK" />
      <Page>
        <MainEquipmentSection sectionId="hoge" heading="主要設備" />
        <SubEquipmentSection sectionId="hoge" heading="その他設備" />
      </Page>
    </>
  );
}
