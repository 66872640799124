import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Animation } from 'gatsby-theme-portfolio-minimal/src/components/Animation';
import { Section } from 'gatsby-theme-portfolio-minimal/src/components/Section';
import type { PageSection } from 'gatsby-theme-portfolio-minimal/src/types';

import CustomTable from './components/Table';

const items = [{
  title: 'プレス機',
  value: 'アマダ 35t',
}, {
  title: 'パンチプレス',
  value: 'VIPROS 357 QUEEN 4X8 アンリツ CNC TPPK816D 1000X2000',
}, {
  title: 'NCベンダー',
  value: 'コマツ PHS-50, アマダ 80t 2400, アマダ RG 25t 1200',
}, {
  title: 'シャーリングマシン',
  value: 'アマダ 2400 t4.5',
}, {
  title: 'スポット溶接',
  value: '3台',
}, {
  title: '溶接機',
  value: 'ダイヘン 30K 4台',
}, {
  title: 'タッピングマシン',
  value: 'キラ、日立、ブラザー',
}, {
  title: 'コンプレッサー',
  value: 'イワタ　3台',
}, {
  title: 'CAD/CAM',
  value: '3台',
}, {
  title: '受入フォーマット',
  value: 'DXF/DWG',
}, {
  title: 'ボール盤',
  value: 'キラ、ブラザー、日立',
}, {
  title: '三本ロール',
  value: '600',
}, {
  title: 'エアータッパー',
  value: '2000×1000',
}, {
  title: 'バリ取り機',
  value: 'DB600R 三菱炭酸ガス',
}, {
  title: 'レーザー加工機',
  value: 'HV2-R',
}, {
  title: 'フユク　バキュームリフト',
  value: '1台',
}, {
  title: 'フォークリフト',
  value: '2.5t',
}];


const MainEquipmentSection: React.FC<PageSection> = (props) => {
  return (
    <Animation type="fadeUp">
      <Section anchor={props.sectionId} heading={props.heading}>
        <div className="grid grid-cols-1 gap-10 md:grid-cols-2">
          <div>
            <StaticImage src="../../content/images/05.jpg" alt="プレスブレーキ PHS-50" />
            <p>プレスブレーキ PHS-50</p>
          </div>
          <div>
            <StaticImage src="../../content/images/06.jpg" alt="ベンダー FaBⅢ-8020" />
            <p>ベンダー FaBⅢ-8020</p>
          </div>
        </div>
      </Section>
    </Animation>
  );
};

export default MainEquipmentSection;

export const SubEquipmentSection: React.FC<PageSection> = (props) => {
  return (
    <Animation type="fadeUp">
      <Section anchor={props.sectionId} heading={props.heading}>
        <div className="flex justify-start">
          <CustomTable items={items} />
        </div>
      </Section>
    </Animation>
  );
};
